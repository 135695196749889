/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				var grid_overlay_html = '<div id="grid-overlay"></div>';

				$('body').append( grid_overlay_html );

				var grid_overlay_el = $('#grid-overlay');
				grid_overlay_el.css('height', $(document).height());

				// $(window).keypress(function(event) {
				// 	if(event.keyCode === 103) {
				// 		grid_overlay_el.toggleClass('grid');
				// 		grid_overlay_el.removeClass('baseline');
				// 	}
				// 	if(event.keyCode === 98) {
				// 		grid_overlay_el.toggleClass('baseline');
				// 		grid_overlay_el.removeClass('grid');
				// 	}
				// });

				var nav_toggle = $('.navbar-toggle');

				nav_toggle.bind( 'click', function(e)
				{
					$(this).toggleClass('open');
				});

				var wrapper = $('.wrap');
				var window_height = $(document).height();
				var footer_height = $('.content-info').height();

				wrapper.css({
					'min-height' : window_height - wrapper.position().top - footer_height - 20 + 'px'
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			
			},
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
				var slick = $('#slideshow-banner');
				var slick_bg_el = $('#slideshow-background');
				var container_width = $('.wrap').width();
				var window_width = $(window).width();
				var slick_padding = (window_width - container_width) / 2;
				
				var slick_height = $('.slick-track').height();

				//alert('slick init');
				
				slick.on('init', function(){


					// slick_height = $('.slick-track').height();
					// slick_bg_el.css({
					// 	'height': slick_height + 'px'
					// });

					var slick_prev = $('.slick-prev');
					var slick_next = $('.slick-next');	

					slick_prev.css('left', slick_padding + 'px');
					slick_next.css('right', slick_padding + 'px');
				});

				slick.slick({
					centerMode: true,
					centerPadding: slick_padding + 'px',
					infinite: true,
					autoplay: true,
					autoplaySpeed: 3500,
					dots: true,
				});

				$(window).resize( function(event) {
					container_width = $('.wrap').width();
					window_width = $(window).width();
					slick_padding = (window_width - container_width) / 2;

					slick_height = $('.slick-track').height();

					slick.slick('slickSetOption', 'centerPadding', slick_padding + 'px');
					slick_bg_el.css('height', slick_height + 'px');

					var slick_prev = $('.slick-prev');
					var slick_next = $('.slick-next');	

					slick_prev.css('left', slick_padding + 'px');
					slick_next.css('right', slick_padding + 'px');
				});

			},
			finalize: function() {}
		},
		'single': {
			init: function() {
				var lazy_image = $('.lazy');
				var slick_slides_container = $('#main-slides');
			    var slick_nav_container = $('#nav-slides');

			    lazy_image.lazyload();

			    slick_slides_container.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					fade: true,
					dots: false,
					asNavFor: '#nav-slides',
					adaptiveHeight: true
				});
				
				slick_nav_container.slick({
					slidesToShow: 7,
					slidesToScroll: 1,
					asNavFor: '#main-slides',
					dots: false,
					centerMode: true,
					centerPadding: 0,
					arrows: false,
					focusOnSelect: true,
				});

				var toggle_btn = $('.collapse-desc-btn');
				var desc_el = $('.description');

				toggle_btn.bind( 'click', function(event) {
					// event.preventDefault();
					desc_el
						.toggleClass('collapse');
					$(this)
						.find('span')
						.toggleClass('glyphicon-menu-up', 'glyphicon-menu-down');
				});

				var activePane = '#event';
				$('.toggle_tab').click(function (event) {
					event.preventDefault();

					var id = activePane === '#event' ? '#reservation' : '#event';
					var pane = $(id);
					
					$('.tab-pane').each(function (el) {
						console.debug(el);
						$(this).removeClass('active');
					});
					
					pane.addClass('active');
					if (id === '#reservation') {
						$('body, html').animate({
							scrollTop: pane.offset().top
						}, 600);
					}
					activePane = id;
				});
			}
		},
		'archive': {
			init: function() {
				var container = $('.main');				
				
				var item_height = $('.masonry .item:first-child').height;
				$('.item').css('height', item_height + 'px');

				imagesLoaded( container, function() {
					$('.masonry').masonry({
						itemSelector:'.item'
					});
				});
				
				var archive_nav = $('.nav-filter');
				var archive_nav_link = $('.nav-filter a');

			    var menu_btn = $('.nav-filter a');

			    function set_archive_content( json_content ) {
					console.log(json_content);

					console.log( json_content.get(0) );
			    }
			}
		},
		'page_template_template_team': {
			init: function() {
				// var container = $('.main');				
				
				// imagesLoaded( container, function() {
				// 	$('.masonry').masonry({
				// 		itemSelector:'.item'
				// 	});
				// });
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
